import React, { useCallback, useEffect, useMemo } from 'react';

/* Material UI */
import { createTheme, ThemeProvider } from '@mui/material';

/* Socket IO */
import socketIOClient, { io } from 'socket.io-client';

/* React Redux  */
import { useDispatch, useSelector } from 'react-redux';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Toastify */
import { ToastContainer } from 'react-toastify';

/* Project */
import './App.scss';
import defaultTheme from 'utils/theme';
import { getItemInStorage } from 'utils/functions';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import environments from 'config/environments';
import Router from './handlers/Router';
import { deleteObjectsReducer, getStateFromApi, mergeObjectsReducer } from './app.actions';

function App({ route }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect route for test environment
    if (route) navigate(route);
  }, [route]);

  const user = useSelector((state) => state.app.user);
  const loading = useSelector((state) => state.app.loading);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: environments.GOOGLE_CLIENT_ID,
      });
    }

    dispatch(getStateFromApi());
  }, []);

  const authSocketPayload = useMemo(() => {
    const userToken = getItemInStorage('user')?.token;
    return { token: userToken };
  }, [user]);

  const webSocketInstance = useCallback(() => {
    const payload = { auth: { token: authSocketPayload.token } };
    return environments.WS_ENDPOINT
      ? socketIOClient(environments.WS_ENDPOINT, payload)
      : io(payload);
  }, [authSocketPayload]);

  const socket = useMemo(() => {
    if (user) return webSocketInstance();

    return null;
  }, [user]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        localStorage.setItem('socketId', socket.id);
      });

      // socket.on('exception', (exception) => {
      //   console.log('[Socket] Exception', { exception });
      // });
      //
      // socket.on('disconnect', () => {
      //   console.log('[Socket] Disconnected');
      // });

      socket.on('[Notification]', (data) => {
        defaultSuccessToast({ message: data.message });
      });

      socket.on('[MERGE_OBJECTS]', (data) => {
        dispatch(mergeObjectsReducer(data));
      });

      socket.on('[DELETE_OBJECTS]', (data) => {
        dispatch(deleteObjectsReducer(data));
      });

      socket.on('[QUEUE_RESPONSE]', (data) => {
        defaultSuccessToast({ message: 'A new action is register' });
        // eslint-disable-next-line no-console
        console.table({ data });
      });

      return () => {
        socket.disconnect();
      };
    }

    return () => {};
  }, [socket]);

  const theme = createTheme(defaultTheme);

  return (
    <ThemeProvider theme={theme}>
      <Router user={user} loading={loading} />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
