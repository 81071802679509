const BASE = '[Holidays]';

const HolidasTypes = {
  FETCH_HOLIDAYS: `${BASE} FETCH_HOLIDAYS`,
  FETCH_HOLIDAYS_SUCCESS: `${BASE} FETCH_HOLIDAYS_SUCCESS`,
  FETCH_HOLIDAYS_FAIL: `${BASE} FETCH_HOLIDAYS_FAIL`,
  SEND_DESCRIPTION: `${BASE} SEND_DESCRIPTION`,
  SEND_DESCRIPTION_SUCCESS: `${BASE} SEND_DESCRIPTION_SUCCESS`,
  SEND_DESCRIPTION_FAIL: `${BASE} SEND_DESCRIPTION_FAIL`,
  CHANGE_PAGINATION: `${BASE} CHANGE_PAGINATION`,
};
export default HolidasTypes;
