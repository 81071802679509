import { createAction } from '@reduxjs/toolkit';
import HolidasTypes from './holidays.types';

export const fetchHolidays = createAction(HolidasTypes.FETCH_HOLIDAYS);
export const fetchHolidaysSuccess = createAction(HolidasTypes.FETCH_HOLIDAYS_SUCCESS);
export const fetchHolidaysFail = createAction(HolidasTypes.FETCH_HOLIDAYS_FAIL);
export const sendDescription = createAction(HolidasTypes.SEND_DESCRIPTION);
export const sendDescriptionSuccess = createAction(HolidasTypes.SEND_DESCRIPTION_SUCCESS);
export const sendDescriptionFail = createAction(HolidasTypes.SEND_DESCRIPTION_FAIL);
export const changePagination = createAction(HolidasTypes.CHANGE_PAGINATION);

const HolidaysActions = {
  fetchHolidays,
  fetchHolidaysSuccess,
  fetchHolidaysFail,
  sendDescription,
  sendDescriptionSuccess,
  sendDescriptionFail,
};
export default HolidaysActions;
