import { put, takeLatest, spawn } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import {
  fetchHolidays,
  fetchHolidaysSuccess,
  fetchHolidaysFail,
  sendDescription,
  sendDescriptionSuccess,
  sendDescriptionFail,
} from './holidays.actions';

function* fetchHoliday() {
  yield takeLatest(fetchHolidays, function* fetchHolidaysToApi(action) {
    const { searchValue, pageSize, currentPage } = action.payload;
    const url = `api/v1/holiday?search=${searchValue}&take=${pageSize}&page=${currentPage}`;
    const response = yield apiRequest(url, {
      method: 'GET',
    });

    if (response) {
      yield put(
        fetchHolidaysSuccess({
          data: response.objects,
          pagination: {
            currentPage: response.pagination.currentPage,
            totalPages: response.pagination.totalPages,
          },
        }),
      );
    } else {
      yield put(fetchHolidaysFail({ message: response.message }));
    }
  });
}

function* updateDescription() {
  yield takeLatest(sendDescription, function* sendDescriptionToApi(action) {
    const { id, description } = action.payload;
    const url = `api/v1/holiday/${id}`;
    const response = yield apiRequest(url, {
      method: 'PATCH',
      body: JSON.stringify({ description }),
    });

    if (response) {
      yield put(sendDescriptionSuccess({ id, description }));
    } else {
      yield put(sendDescriptionFail({ message: response.message }));
    }
  });
}

export default function* HolidaySaga() {
  yield spawn(fetchHoliday);
  yield spawn(updateDescription);
}
