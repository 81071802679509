import {
  fetchHolidays,
  fetchHolidaysFail,
  fetchHolidaysSuccess,
  sendDescription,
  sendDescriptionFail,
  sendDescriptionSuccess,
  changePagination,
} from './holidays.actions';

const { createReducer } = require('@reduxjs/toolkit');

const initialState = {
  pagination: {
    currentPage: 1,
    pageSize: 8,
    totalPages: 1,
    searchValue: '',
  },
  data: [],
  isLoading: false,
  isLoadingSend: false,
  errorSend: null,
};

const HolidaysReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchHolidays, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchHolidaysSuccess, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.pagination = {
        ...state.pagination,
        ...action.payload.pagination,
      };
    })
    .addCase(fetchHolidaysFail, (state) => {
      state.isLoading = false;
    })
    .addCase(sendDescription, (state) => {
      state.isLoadingSend = true;
    })
    .addCase(sendDescriptionSuccess, (state, action) => {
      state.isLoadingSend = false;
      const { id, description } = action.payload;
      state.data = state.data.map((holiday) => {
        if (holiday.id === id) {
          return {
            ...holiday,
            description,
          };
        }
        return holiday;
      });
    })
    .addCase(sendDescriptionFail, (state) => {
      state.isLoadingSend = false;
      state.errorSend = true;
    })
    .addCase(changePagination, (state, action) => {
      state.pagination.currentPage = action.payload.currentPage;
    });
});

export default HolidaysReducer;
