import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import CustomTable from 'content/features/other/components/CustomTable';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, TextField } from '@mui/material';
import { sendDescription, changePagination } from '../holidays.actions';
// import { updateHolidayDescription } from 'path-to-your-redux-actions'; // Ajusta la ruta

function HolidaysTable() {
  const dispatch = useDispatch();
  const { data: holidayData, pagination } = useSelector((state) => state.holiday);

  // Estado para rastrear la fila que está en modo de edición
  const [editingId, setEditingId] = useState(null);
  // Estado para rastrear la descripción actualizada
  const [updatedDescription, setUpdatedDescription] = useState('');

  // Función para manejar la edición
  const handleEdit = (id, currentDescription) => {
    setEditingId(id);
    setUpdatedDescription(currentDescription);
  };

  // Función para manejar el cambio en el input
  const handleDescriptionChange = (e) => {
    setUpdatedDescription(e.target.value);
  };

  // Función para manejar la cancelación de la edición
  const handleCancel = () => {
    setEditingId(null);
    setUpdatedDescription('');
  };

  // Función para manejar el guardado de los cambios
  const handleSave = (id) => {
    if (updatedDescription.trim() === '') {
      // eslint-disable-next-line no-alert
      alert('La descripción no puede estar vacía.');
      return;
    }
    // Despachar la acción para actualizar la descripción en Redux
    dispatch(sendDescription({ id, description: updatedDescription }));
    setEditingId(null);
    setUpdatedDescription('');
  };

  // Formatear las fechas
  const formattedHolidayData = useMemo(
    () =>
      holidayData.map((holiday) => ({
        ...holiday,
        date: moment(holiday.date).format('DD-MM-YYYY'),
      })),
    [holidayData],
  );

  const handleChange = (value) => {
    dispatch(changePagination({ currentPage: value }));
  };

  const data = useMemo(
    () => ({
      headers: [
        { name: 'Fecha', itemKey: 'date' },
        { name: 'Descripción', itemKey: 'description' },
        { name: 'Acciones', itemKey: 'actions' },
      ],
      items: formattedHolidayData.map((holiday) => {
        if (editingId === holiday.id) {
          return {
            ...holiday,
            description: (
              <TextField
                value={updatedDescription}
                onChange={handleDescriptionChange}
                variant="outlined"
                size="small"
                fullWidth
              />
            ),
            actions: (
              <>
                <IconButton
                  color="primary"
                  onClick={() => handleSave(holiday.id)}
                  aria-label="guardar"
                  size="large">
                  <SaveIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={handleCancel}
                  aria-label="cancelar"
                  size="large">
                  <CancelIcon />
                </IconButton>
              </>
            ),
          };
        }
        return {
          ...holiday,
          actions: (
            <IconButton
              color="primary"
              onClick={() => handleEdit(holiday.id, holiday.description)}
              aria-label="editar"
              size="large">
              <EditIcon />
            </IconButton>
          ),
        };
      }),
    }),
    [formattedHolidayData, editingId, updatedDescription, dispatch],
  );

  const fetchOptions = {
    totalPage: pagination.totalPages,
    actualPage: pagination.currentPage,
    pageSize: pagination.pageSize,
    searchValue: pagination.searchValue,
    dateValue: '',
    processValue: '',
    statusValue: '',
  };

  return (
    <CustomTable
      color="primary"
      data={data}
      isPaginated
      onChange={handleChange}
      paginationOptions={fetchOptions}
    />
  );
}

export default HolidaysTable;
